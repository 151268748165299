<template>
  <section id="profile">
    <!--Header-->
    <!-- In organizations (when "fitProfileImage" is false) we need pb-2 in header for tabs-->
    <b-card alt="cover photo" class="profile-header mb-2 pb-0" :class="{ 'pb-2' : !fitProfileImage }">
      <safe-img v-if="$route.name !== 'societies-details'" :src="backgroundUrl" :placeholder="defaultBg" class="card-img-top profile-background" />
      <b-card-body class="p-0 pt-md-2 mb-0" :class="{ 'mb-1' : !fitProfileImage, 'mb-2 pb-75 d-flex flex-wrap': $route.name == 'societies-details'}">
        <div class="position-relative">
          <div class="profile-img-container d-flex" :class="{'profile-img-container--society': $route.name == 'societies-details'}">
            <safe-img
              :src="profilePhoto"
              :placeholder="avatarPlaceholder"
              alt="profile photo"
              class="profile-img"
              :class="{'profile-img--fit' : fitProfileImage}"
            />
          </div>
        </div>
        <div
          class=" pr-0 pt-3 pb-1 p-md-0 d-flex align-items-center justify-content-between flex-wrap"
          :class="$route.name != 'societies-details' ? 'profile-title' : isSmall ? 'ml-2' : 'profile-title'"
        >
          <div class="m-0 mb-1">
            <div class="d-flex flex-wrap">
              <h2 class="pr-75">
                {{ name }}
              </h2>
              <b-icon-person-check-fill
                v-if="hasRelation && !isMyProfile"
                variant="primary"
                scale="1.4"
                class="mt-50"
                style="cursor: pointer"
                @click="infoConnection = true"
              />
            </div>
            <p class="mb-0">
              {{ translatedHeadline }}
            </p>
            <div>
              <span v-for="(info, index) in memberOrganizations($route.params.username)" :key="index">
                <b-link class="mr-50" @click="organizationLocation(info)">{{ getOrganizationName(info) }}</b-link>
              </span>
            </div>
          </div>
          <div v-if="hasActionButtons && member && member.status === 3">
            <!-- Chat button -->
            <b-button
              v-if="
                $route.name !== 'organization' &&
                  hasChatIndividualAddOn &&
                  !noContact &&
                  isChatAddonInstalled
              "
              v-b-tooltip.hover.bottom
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="px-2 mt-0 mr-1"
              :title="$t('backoffice.roles.permissions.chat')"
              @click="$emit('open-chat', true)"
            >
              <feather-icon size="18" icon="MessageCircleIcon" />
            </b-button>
            <!-- Request Meeting button -->
            <b-button
              v-if="
                $route.name !== 'organization' &&
                  hasNetworkingOn &&
                  !noCalendar && 
                  hasMeetingInstalled
              "
              v-b-tooltip.hover.bottom
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :title="$t('backoffice.roles.permissions.meeting')"
              class="px-2 mt-0"
              @click="$emit('request-meeting', name)"
            >
              <feather-icon icon="CalendarIcon" size="18" />
            </b-button>
            <!-- Connections button -->
            <!--b-button
              v-if="$route.name !== 'organization' && !hasRelation && !isMyProfile"
              v-b-tooltip.hover.bottom
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="px-2 mt-0 ml-1"
              :title="$t('members.no-friend-tooltip')"
              style="padding-top: 11.5px!important"
              @click="handleCreateItem()"
            >
              <b-icon-person-plus variant="white" scale="1.2" style="cursor: pointer;" />
            </b-button-->
          </div>
          <!-- Enter button -->
          <div v-if="item">
            <communities-others-button
              v-if="$route.name === 'organization' || currentCollective.mainType === 'Organization'"
              :item="item"
              :item-community="item"
              :item-type="itemType"
              class="mt-n1"
            />
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- Profile Information -->
    <section id="profile-info">
      <slot />
    </section>
    <b-modal
      v-model="infoConnection"
      :title="$t('members.friend-tooltip')"
      size="lg"
      hide-footer
    >
      <p class="text-center mt-1">
        {{ $t('members.friend-message') }}
      </p>
      <div class="d-flex flex-wrap justify-content-center">
        <b-button variant="outline-primary" class="mb-1 mr-50" @click="handleDeleteItem()">
          {{ $t('members.desconnection') }}
        </b-button>
        <b-button variant="primary" class="mb-1 mr-50" :to="connectionLocation">
          {{ $t('members.friend-button') }}
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
// import { BCard, BCardBody, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import Service from '@/config/service-identifiers';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
// import SafeImg from '@core/components/safe-img/SafeImg.vue';
// import CommunitiesOthersButton from '@/@core/components/communities-others/CommunitiesOthersButton.vue';

export default {
  name: 'ProfileDetails',
  directives: {
    Ripple,
  },
  components: {
    SafeImg: () => import('@core/components/safe-img/SafeImg.vue' /* webpackChunkName: "SafeImg" */),
    CommunitiesOthersButton: () => import('@/@core/components/communities-others/CommunitiesOthersButton.vue' /* webpackChunkName: "CommunitiesOthersButton" */),
  },
  props: {
    profileImage: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    headline: {
      type: [Object, String],
      default: () => {},
    },
    avatarPlaceholder: {
      type: String,
      required: true,
    },
    headerBackground: {
      type: String,
      default: null,
    },
    fitProfileImage: Boolean,
    hasActionButtons: Boolean,
    disableMeeting: {
      type: Boolean,
      default: false,
    },
    canEdit: Boolean,
    briefInfo: {
      type: Array,
      default: () => {},
    },
    member: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    itemType: {
      type: String,
      default: '',
    },
    hasRelation: Boolean,
  },
  data() {
    return {
      mainMembers: [],
      organizations: [],
      enabledOrganizationApp: false,
      mainOrganizations: null,
      noCalendar: false,
      noCalendarMessage: false,
      noContact: false,
      noContactMessage: false,
      infoConnection: false,
      isSmall: false,
    };
  },
  computed: {
    connectionLocation() {
      return {
        name: 'myConnections',
        params: {
          communityId: this.$route.params.communityId,
        },
      };
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    hasMain() {
      return this.$store.getters.mainCollective?.slug;
    },
    isChatAddonInstalled() {
      return this.$store.getters.installedApps?.data?.filter(
        (item) => item.key === 'chat-individual',
      ).length > 0;
    },
    profilePhoto() {
      return getImageResource(this.profileImage);
    },
    backgroundUrl() {
      return getImageResource(this.headerBackground);
    },
    translatedHeadline() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.headline);
    },
    defaultBg() {
      return HeaderPlaceholder;
    },
    hasMeetingInstalled() {
      return this.$store.getters.installedApps?.data?.filter(
        (item) => item.key === 'meetings',
      ).length > 0;
    },
    hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    isMyProfile() {
      return this.loggedMember.username === this.$route.params.username;
    },
    hasNetworkingOn() {
      if (this.member.networking !== null) {
        if (this.member.networking === 0) {
          this.noCalendar = true;
          this.noCalendarMessage = this.$t(
            'header.action.your-meeting-disabled',
          );
          return;
        }
        if (this.member.networking === null && this.loggedMember?.networking === null) {
          return true;
        }
        if (this.member.networking.meetings === 0 || this.loggedMember?.networking?.meetings == 0) {
          return false;
        }
        return true;
      }
      return true;
    },
    hasChatIndividualAddOn() {
      if (this.member?.networking !== null) {
        if (this.member.networking === 0) {
          this.noContact = true;
          this.noContactMessage = this.$t('header.action.your-chat-disabled');
          return;
        }
        if (this.member.networking === null && this.loggedMember?.networking === null) {
          return true;
        }
        if (this.member.networking.chat === 0 || this.loggedMember?.networking?.chat == 0) {
          return false;
        }
        return true;
      }
      return true;
    },
  },
  watch: {
    mainMembers() {
      this.memberOrganizations(this.$route.params.username);
    },
  },
  async created() {
    await this.getInstalledApps();
    await this.installedMainApps();
    if (this.hasMain) {
      await this.getLoggedMember();
    }
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 998;
    },
    organizationLocation(organization) {
      const main = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}', (this.$store.getters.mainCollective?.subdomain || this.currentCollective?.subdomain) || 'app',
      ).replace('{slug}', this.$store.getters.mainCollective?.slug || this.currentCollective?.slug);
      if (this.$store.getters.mainCollective?.slug) {
        window.open(
          `${main}/organizations/${organization.slug}`,
          '_blank',
        );
      } else {
        window.location.href = `${main}/organizations/${organization.slug}`;
      }
    },
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    memberOrganizations(item) {
      if (this.mainMembers.length > 0) {
        return this.mainMembers?.filter((i) => i.username === item)[0]?.organizations;
      }
      return this.briefInfo;
    },
    async getLoggedMember() {
      await this.installedMainApps();
      const response = await this.$store.$service[Service.BackendClient].get('/communityMembers', {
        params: {
          communitySlug: this.hasMain,
          username: this.$route.params.username,
          isForProfile: true,
        },
      });
      this.mainMembers = response.data.data;
      return response;
    },
    async installedMainApps() {
      const response = await this.$store.dispatch('mainApps');
      this.enabledOrganizationApp = response.data.apps[32] || false;
    },
    async handleDeleteItem() {
      try {
        await this.$store.dispatch('deleteItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [this.member.key, this.loggedMember.key],
            },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('members.desconnection-alert'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.hasRelation = false;
        this.infoConnection = false;
        this.$emit('removed-relation', false);
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async handleCreateItem() {
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [this.member.key, this.loggedMember.key],
            },
          },
        });
        this.$emit('removed-relation', true);
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    getOrganizationName(organization) {
      if (typeof organization.name === 'string') {
        return organization.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, organization.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/page-profile.scss";
.placeholder {
  width: 35%;
}
</style>
