var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"profile"}},[_c('b-card',{staticClass:"profile-header mb-2 pb-0",class:{ 'pb-2' : !_vm.fitProfileImage },attrs:{"alt":"cover photo"}},[(_vm.$route.name !== 'societies-details')?_c('safe-img',{staticClass:"card-img-top profile-background",attrs:{"src":_vm.backgroundUrl,"placeholder":_vm.defaultBg}}):_vm._e(),_c('b-card-body',{staticClass:"p-0 pt-md-2 mb-0",class:{ 'mb-1' : !_vm.fitProfileImage, 'mb-2 pb-75 d-flex flex-wrap': _vm.$route.name == 'societies-details'}},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"profile-img-container d-flex",class:{'profile-img-container--society': _vm.$route.name == 'societies-details'}},[_c('safe-img',{staticClass:"profile-img",class:{'profile-img--fit' : _vm.fitProfileImage},attrs:{"src":_vm.profilePhoto,"placeholder":_vm.avatarPlaceholder,"alt":"profile photo"}})],1)]),_c('div',{staticClass:" pr-0 pt-3 pb-1 p-md-0 d-flex align-items-center justify-content-between flex-wrap",class:_vm.$route.name != 'societies-details' ? 'profile-title' : _vm.isSmall ? 'ml-2' : 'profile-title'},[_c('div',{staticClass:"m-0 mb-1"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('h2',{staticClass:"pr-75"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.hasRelation && !_vm.isMyProfile)?_c('b-icon-person-check-fill',{staticClass:"mt-50",staticStyle:{"cursor":"pointer"},attrs:{"variant":"primary","scale":"1.4"},on:{"click":function($event){_vm.infoConnection = true}}}):_vm._e()],1),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translatedHeadline)+" ")]),_c('div',_vm._l((_vm.memberOrganizations(_vm.$route.params.username)),function(info,index){return _c('span',{key:index},[_c('b-link',{staticClass:"mr-50",on:{"click":function($event){return _vm.organizationLocation(info)}}},[_vm._v(_vm._s(_vm.getOrganizationName(info)))])],1)}),0)]),(_vm.hasActionButtons && _vm.member && _vm.member.status === 3)?_c('div',[(
              _vm.$route.name !== 'organization' &&
                _vm.hasChatIndividualAddOn &&
                !_vm.noContact &&
                _vm.isChatAddonInstalled
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"px-2 mt-0 mr-1",attrs:{"variant":"primary","title":_vm.$t('backoffice.roles.permissions.chat')},on:{"click":function($event){return _vm.$emit('open-chat', true)}}},[_c('feather-icon',{attrs:{"size":"18","icon":"MessageCircleIcon"}})],1):_vm._e(),(
              _vm.$route.name !== 'organization' &&
                _vm.hasNetworkingOn &&
                !_vm.noCalendar && 
                _vm.hasMeetingInstalled
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"px-2 mt-0",attrs:{"variant":"primary","title":_vm.$t('backoffice.roles.permissions.meeting')},on:{"click":function($event){return _vm.$emit('request-meeting', _vm.name)}}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"18"}})],1):_vm._e()],1):_vm._e(),(_vm.item)?_c('div',[(_vm.$route.name === 'organization' || _vm.currentCollective.mainType === 'Organization')?_c('communities-others-button',{staticClass:"mt-n1",attrs:{"item":_vm.item,"item-community":_vm.item,"item-type":_vm.itemType}}):_vm._e()],1):_vm._e()])])],1),_c('section',{attrs:{"id":"profile-info"}},[_vm._t("default")],2),_c('b-modal',{attrs:{"title":_vm.$t('members.friend-tooltip'),"size":"lg","hide-footer":""},model:{value:(_vm.infoConnection),callback:function ($$v) {_vm.infoConnection=$$v},expression:"infoConnection"}},[_c('p',{staticClass:"text-center mt-1"},[_vm._v(" "+_vm._s(_vm.$t('members.friend-message'))+" ")]),_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},[_c('b-button',{staticClass:"mb-1 mr-50",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.handleDeleteItem()}}},[_vm._v(" "+_vm._s(_vm.$t('members.desconnection'))+" ")]),_c('b-button',{staticClass:"mb-1 mr-50",attrs:{"variant":"primary","to":_vm.connectionLocation}},[_vm._v(" "+_vm._s(_vm.$t('members.friend-button'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }